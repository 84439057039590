declare const LOCAL_API: boolean;

const config = {
  pharmaxoApi: '#{WebAPIBaseUrl}', // PharmaxoApi
  referenceApi: '#{ReferenceAPIBaseUrl}', // ReferenceApi
  timeout: 500 * 1000,
  pharmaxoCustomerId: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
  courierCutoffs: {
    LFAndE: '#{LfeCutoffTime}'
  }
};

if (LOCAL_API) {
  config.pharmaxoApi = 'http://127.0.0.1:7777/';
  config.referenceApi = 'http://127.0.0.1:7778/';
  config.courierCutoffs = {
    LFAndE: '20:00'
  }
}

export default config;
